@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #222226;
}

.collapse {
  visibility: visible;
}

.login-image {
  background-image: url("../src/assets/images/loginbg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  max-width: 400px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #ced4da;
}

.form-control::placeholder {
  color: #c8c8c8 !important;
}

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

#wrapper {
  transition: all 0.5s ease;
}

html {
  scroll-behavior: smooth;
}

.login,
.image {
  min-height: 100vh;
}

.signup-link {
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
}

@media (max-width: 768px) {
  .wrapper {
    width: 90%;
    margin: 10px auto;
    padding: 10px;
  }
}

.google_icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.main_description {
  font-size: 16px;
  color: #676767;
  text-align: center;
  width: 50%;
  margin: auto;
}

.text-center {
  position: relative;
}

.main_heading {
  position: relative;
  z-index: 2;
}

.heading_bg {
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 42%;
  height: auto;
  z-index: 1;
}

.main_description {
  position: relative;
  z-index: 2;
}

.uxdriven_img img {
  height: 40px;
  width: 40px;
}

#pagination_section {
  margin: 0 auto;
  display: block;
}

.page-header {
  text-align: center;
  font-size: 1.5em;
  font-weight: normal;
  border-bottom: 1px solid #ddd;
  margin: 30px 0;
}

#pagination {
  margin: 0;
  padding: 0;
  text-align: center;
}

#pagination li {
  display: inline;
}

#pagination li a {
  display: inline-block;
  text-decoration: none;
  padding: 5px 10px;
  color: #000;
  margin: 5px;
  background-color: #f2f2f2;
  font-size: 14px;
}

#pagination li a {
  border-radius: 5px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

/* 
.catalogue_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 8px;
  text-transform: capitalize;
  background-color: #613ab1;
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: #fff;
} */

.table th {
  background-color: #cdcdcd !important;
}

.disable_heading {
  font-size: 16px;
  font-style: normal;
  color: #736f6f;
  font-weight: 600;
}

.my-centered-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter_img {
  min-height: 100px;
  border: 1px solid #ddd;
  width: 100%;
  box-sizing: border-box;
}

.image_selector {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.img-thumbnail {
  max-width: 100%;
  max-height: 160px;
  object-fit: cover;
}

.controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.nav-button:disabled {
  background-color: transparent;
  cursor: not-allowed;
}

.proposal_tabs {
  color: #000;
  background-color: #602323;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
}

.nav-arrow {
  cursor: pointer;
}

.values-container {
  /* width: 100px; */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

.values {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000 !important;
}

/* 
.card_bg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  min-height: 20vh;
}

.filter_selcted_img .card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  min-height: 20vh;
} */

/* Add this to your CSS or SCSS file */
.catalogue_button {
  background-color: #613ab1;
  /* Default background color */
  color: #ffffff;
  /* Default text color */
  border: none;
  padding: 7px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.catalogue_button:disabled {
  background-color: #dcdcdc;
  /* Disabled background color */
  color: #a1a1a1;
  /* Disabled text color */
  cursor: not-allowed;
}

.catalogue_button:disabled img {
  filter: grayscale(100%) brightness(50%);
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.media-body {
  max-width: 200px;
}

.email-text {
  font-size: 12px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  z-index: 1000;
}

.popup.success {
  background-color: #4caf50;
}

.popup.error {
  background-color: #f44336;
}

.password-input-wrapper {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes animFw {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes coli1 {
  0% {
    transform: rotate(-45deg) translateX(0px);
    opacity: 0.7;
  }

  100% {
    transform: rotate(-45deg) translateX(-45px);
    opacity: 0;
  }
}

@keyframes coli2 {
  0% {
    transform: rotate(45deg) translateX(0px);
    opacity: 1;
  }

  100% {
    transform: rotate(45deg) translateX(-45px);
    opacity: 0.7;
  }
}

.custom-header {
  font-family: "Inter", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(
    90deg,
    #3c79e4 0%,
    #9a4076 44.35%,
    #d34e3c 73.12%,
    #d49c2b 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 3rem;
  margin-top: 4rem;
}

/* Animation for modal fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Animation for modal fade-out */
@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

/* Modal animation classes */
.modal-enter {
  animation: fadeIn 0.5s ease-out forwards;
}

.modal-exit {
  animation: fadeOut 0.5s ease-out forwards;
}

.cube {
  height: 80px;
  width: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6px;
}

.cube_item {
  height: 40px;
  width: 40px;
  border-radius: 10px;
  transition: all 0.2s ease-in;
}

.cube_x {
  background-color: blueviolet;
  animation: animateLoaders 1s infinite;
}

.cube_y {
  background-color: aqua;
  animation: animateLoaders 1s 0.5s infinite;
}

@keyframes animateLoaders {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}

@media screen and (min-width: 1024px) {
  .responsive-ml {
    margin-left: 150px;
  }
}

@media screen and (min-width: 768px) {
  .responsive-img-p {
    margin-left: 200px;
  }
}

.pdf-content {
  display: none;
}

.browser-content {
  display: block;
}

@media print {
  .pdf-content {
    display: block;
  }

  .browser-content {
    display: none;
  }
}

/* Hide scrollbar for Chrome, Safari, and Edge */
.custom-scrollbar::-webkit-scrollbar {
  display: none;
}
